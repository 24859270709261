.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.article-container h1 {
  font-size: 28px;
  color: #06618a;
  text-align: center;
  margin-bottom: 20px;
}

.article-container h2 {
  font-size: 22px;
  color: #0a79b4;
  margin-top: 30px;
}

.article-container h3 {
  font-size: 18px;
  color: #0b6e9f;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.article-container p {
  font-size: 16px;
  margin-bottom: 15px;
  text-align: justify;
}

/* Style pour les CTA */
.cta {
  text-align: center !important;
  font-size: 1.2rem;
  font-weight: bold;
  color: #044e72;
  margin-block: 60px !important;
}

.cta a {
  display: inline-block;
  background: linear-gradient(135deg, #0884bf, #06618a);
  color: white !important;
  padding: 12px 25px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.cta a:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #0a79b4, #044e72);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .article-container {
    padding: 10px;
  }

  .article-container h1 {
    font-size: 24px;
  }

  .article-container h2 {
    font-size: 20px;
  }

  .article-container p {
    font-size: 14px;
  }

  .cta {
    font-size: 16px;
    padding: 12px;
  }
}
