.containerProg {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  &__content {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 1rem;

    &__img,
    &__imgTitle {
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    &__imgF {
      width: 100%;
    }
  }

  &__reverse {
    display: flex;
    flex-direction: column;
  }
}

.linkProg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  color: $black;
  text-transform: uppercase;
  margin-top: 0.5rem;
  font-family: $base-font-family;
  cursor: pointer;
  font-size: 13px;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    color: $black;
  }
}

.fleche-droite {
  width: 2rem;

  cursor: pointer;

  &:focus {
    outline: none;
  }
}

@media (min-width: $tablet-portrait) {
  .containerProg {
    width: 100%;
    max-width: 1250px;
    &__box {
      display: flex;
      flex-direction: column;
      width: 45%;
      //text-align: justify;
      //padding-bottom: 2.5rem;
    }

    &__content {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: stretch;
      margin: 0;

      &__img {
        // max-height: 80vh;
        width: 45%;
        margin-right: 1rem;
        margin-bottom: 0;
        /*          height: 40%;
          width: 45%;
          margin-right: 1rem;*/
      }
      &__imgTitle {
        width: 80%;
        margin: 0 auto;
        height: auto;
      }
    }
  }
}

.btnProg {
  margin-top: 0px !important;
}
.paraProg {
  font-size: 14px;
  margin-bottom: 0px !important;
}

.titleMargeDouble {
  margin-top: 20px !important;
}

.paraCarte {
  font-size: 14px;
}

//titres
.titrePec {
  font-family: "Norican", cursive;
  font-size: 3rem; /* 48px */
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  margin: 0 0 1.5rem 0;
}

@media (max-width: $mobile) {
  .titrePec {
    font-size: 2.5rem; /* 48px */
    line-height: 1.2;
  }
}

//liste à puces
ul {
  list-style-type: "- ";
  padding-left: 1rem;
  text-align: left;
}

hr {
  width: 50%;
  margin-inline: auto;
}
