// ---------- GÉNÉRAL ----------

@mixin paragraph {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
}

@mixin onglet-title {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $black;
}

@mixin title {
  font-family: $base-font-family;
  font-size: $heading-font-size;
  font-weight: $base-font-weight;
}

@mixin img {
  width: 100%;
  height: auto;
}

// ---------- FLEX ----------

@mixin flex-center-column {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

@mixin flex-center-row {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

// ------- CHOICE --------

@mixin container-link-choice {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

@mixin link-choice {
  display: flex;
  align-items: center;
  text-align: center;
  color: $white !important;
  font-family: $heading-font-family;
  font-size: 1rem;
  padding: 0.5rem 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    outline: none;
    color: $white;
    transform: scale(1.1);
    &:focus {
      text-decoration: none;
      outline: none;
    }
  }
}

// ---------- AGENDA ----------
@mixin card-agenda {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  width: 60px;
  height: 19%;
  font-size: 1rem;
  padding: 0 0.5rem;
}

// -------- LAPTOP ONLINE ------
@mixin topContainer {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}
