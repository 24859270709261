.offreContainer {
  width: 100%;
  margin-top: 3rem;
}

.blueContainer,
.pinkContainer {
  width: 90%;
  margin: auto;
}

.blueBorder {
  border-top: 3px solid $blue;
  margin: 1rem auto 4rem auto;
}

.pinkBorder {
  border-top: 3px solid $pink;
  margin: 1rem auto 2rem auto;
}

.offerImg {
  margin: 0.8rem 0;
  width: 100%;
  height: auto;
}

.titleImg {
  width: 100%;
  height: auto;
}

.fleche {
  height: 1.3rem;
  margin-left: 0.5rem;
}

.lien {
  text-decoration: none;
  color: black;
  font-size: 12px;
}

.text {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 1rem;
}

@media (min-width: $tablet-portrait) {
  .offreContainer {
    margin-top: 0;
  }

  .blueContainer,
  .pinkContainer {
    display: flex;
    width: 65%;
    margin: 0 auto;
  }

  .blueBorder,
  .pinkBorder {
    display: none;
  }

  .contentBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-left: 2rem;
  }

  .offerImg {
    width: 50%;
    height: 50%;
    margin: 0;
  }
}

@media (min-width: $tablet-landscape) {
  .blueContainer,
  .pinkContainer {
    width: 75%;
  }
}
