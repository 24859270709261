.article__title {
  text-align: center;
  margin: 1em;
}

.article__wrapper {
  width: 100%;
}

.article__image {
  width: 100%;
  margin: 0.5em auto;
}

.article__content {
  margin: 3em 0;
}

.article__para {
  width: 90%;
  margin: 1em auto;
}
.article__para h3 {
  font-weight: bolder;
}
.article__para p {
  font-size: 18px;
}

/*
THUMBNAIL*/
.thumbail__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}
.thumbail__wrapper {
  width: 100%;
  padding: 0.5rem;
  margin: 2rem auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
}

.thumbail__imageContainer--img {
  width: 100%;
  height: auto;
}

.thumbail__content {
  display: flex;
  flex-direction: column;

  &--title {
    text-align: center;
    font-size: 1.9rem;
    line-height: 1.4;
    font-family: $base-font-family;

    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }

  &--text {
    text-align: center;
  }
}

.more {
  border: 1px solid $blue;
  color: $blue;
  font-weight: $bold;
  padding: 0.5rem;
  width: 50%;
  margin: auto;
  text-align: center;

  &:hover {
    color: $white;
    background-color: $blue;
    text-decoration: none;
  }
}

/* ACTU*/
.actu__title {
  text-align: center;
  margin-bottom: 2em;
}

@media (min-width: $tablet-landscape) {
  .thumbail__wrapper {
    width: 80%;
    flex-direction: row;
  }

  .thumbail__imageContainer {
    width: 40%;
  }

  .thumbail__content {
    width: 40%;
  }
}
