// ------------ COLORS -------------

$pink: #fe77c3;
$purple: #4d59b0;
$blue: #4bc8e8;
$marron: #c38387;
$yellow: #f8b733;
$grey: #a2aacc;
$green: #3bbb34;
$lightblue: #b6daee;
$lightpink: #d292ab;
$dark-grey: #5a5a5a;
$dark-pink: #e4256e;
$dark-blue: #3b8be4;
$black: #000000;
$white: #ffffff;
$menuBg: #242424;
$linkMenu: #222;

// AGENDA
$francais: #e4256e;
$physique: #0bb3e2;
$histoire: #77a036;
$math: #d657ae;
$anglais: #836bc9;

// BORDER
$borderGrey: #e7e7e6;
$borderBottom: 0.5px solid rgb(214, 214, 214);

// ------------ FONTS -------------

// FONT WEIGHT
$light: 100;
$regular: 400;
$bold: 600;

// BASE FONT
$base-font-family: roboto-condensed;
$base-font-size: 1rem;
$base-font-weight: $regular;
$base-line-height: 1.64rem;

// HEADING
$heading-font-size: 1.3rem;
$heading-font-weight: $bold;

// ONLINE HEADING
$heading-font-family: blenda;

// ------------ RESPONSIVE -------------
$mobile: 640px;
$tablet-portrait: 1015px;
$tablet-landscape: 1081px;
$laptop: 1082px;
