.title {
  @include title;
}

.flecheHome {
  width: 3.5rem;
}

//
.text-justify {
  text-align: justify;
}

.swiper-button-next {
  right: 15px !important;
}
.swiper-button-prev {
  left: 15px !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: #ee76c4 !important;
  opacity: 0.5;
  width: 1em !important;
}

.testimonial__box--text::-webkit-scrollbar {
  width: 5px !important;
}
.testimonial__box--text::-webkit-scrollbar-thumb {
  background-color: #fe77c350 !important;
  border-radius: 2px !important;
}

@media (min-width: $mobile) {
  .swiper-button-next {
    right: 30px !important;
  }
  .swiper-button-prev {
    left: 30px !important;
  }
}

.profs-presentations {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blockHome {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
}

.rowBlock {
  display: flex;
  width: 100%;
  height: 30%;
  align-items: center;
  margin-top: 1rem;
}

.blockLeft {
  display: none;
}

.slider {
  width: 95%;
  height: auto;
  margin: auto;
  display: flex;
}

.slider2 {
  width: 100%;
  height: 100%;
}

.cursus-presentations {
  margin-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.profs-presentations {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

// ---- TESTIMONIALS ----

// ---- TeacherCArd -------
.teacher-slide > .swiper-container {
  padding-bottom: 3rem !important;
}
.teacherCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  cursor: grab;
  padding: 0 0 1rem 0;
  margin: auto;
  transition: all 200ms ease-in-out;

  &:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__title {
    font-size: $base-font-size + 0.3rem;
    font-family: $base-font-family;
    font-weight: $base-font-weight + 300;
    margin-top: 2rem;
    margin-bottom: 0;
    height: 3rem;
    color: black;
    text-align: center;

    &__border {
      border: 1px solid black;
      width: 3rem;
      margin: 0.5rem;

      &--name {
        color: black;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.explain {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 8rem auto 3rem auto;
  justify-content: space-around;
  width: 80%;

  &__description {
    text-transform: uppercase;
    font-family: $base-font-family;
    font-size: $base-font-size + 0.2rem;
  }
}
.containerTestimonial {
  width: 100%;
}
.testimonial {
  text-align: center;
  width: 100%;
  margin-top: 2rem;

  &__title {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    height: 25rem;

    &--text {
      margin-bottom: 0;
      overflow-y: scroll;
    }

    &--parent {
      font-weight: $bold;
      margin-bottom: 2rem;
    }
  }
}

.star {
  display: flex;
}
.starNote {
  width: 1rem;
  height: 1rem;
}

.testimonial__box--img {
  width: 3rem;
}

@media (min-width: $tablet-portrait) {
  .flecheHome {
    width: 4rem;
  }

  .explain {
    flex-direction: row;
  }

  .rowBlock {
    height: 20%;
    align-items: center;
  }
  .firstSlide {
    background-color: #f6f6f6;
  }

  .slider2 {
    width: 100% !important;
  }

  .sliderSecond {
    width: 100%;
  }

  .blockLeft {
    display: block;
  }

  .firstSlide {
    margin-top: 0;
  }

  .bgHome {
    background-color: #f6f6f6;
  }

  .testimonial {
    width: 50%;
    margin-top: 0;
  }

  .containerTestimonial {
    display: flex;
  }

  .youtube {
    width: 50%;
  }
}

@media (min-width: $tablet-landscape) {
  // TEACHERSCARD
  .teacherCardBg {
    width: 65vw;
    margin: auto;
  }

  .blockHome {
    width: 65%;
    margin: 20px auto;
    z-index: 99;
  }

  .rowBlock {
    margin-bottom: 2rem;
  }
}

// FlashBanner
/* ---------------------------- */
/* Défilement de droite à gauche */
.marquee-rtl {
  //background-color: #4e5ab1; //bleu Eve
  background-color: #ffc300; //jaune
  //height: 58px;
  height: 68px;
  color: white;
  //font-size: 1.3rem;
  font-size: 2rem;
  font-weight: bold;
  //font-family: "Yusei Magic";
  overflow: hidden; /* important */
}
.marquee-rtl > div {
  display: inline-block; /* important */
  white-space: nowrap; /* important */
  animation: defilement-rtl 70s infinite linear; /* défilement */
  padding: 10px 2em 10px 20%;
}
.marquee-rtl:hover > div {
  animation-play-state: paused; /* met en pause le défilement */
}
@media (max-width: 1440px) {
  .marquee-rtl {
    //height: 38px;
    height: 50px;
    //font-size: 15px;
    font-size: 1.5rem;
  }
  .marquee-rtl > div {
    padding-block: 7px;
  }
}
@media (max-width: 1024px) {
  .marquee-rtl {
    //height: 30px;
    //font-size: 13px;
  }
  .marquee-rtl > div {
    padding-block: 5px;
  }
}
@media (max-width: 425px) {
  .marquee-rtl {
    //font-size: 12px;
    height: 40px;
    font-size: 1rem;
  }
  .marquee-rtl > div {
    padding-block: 6px;
  }
}

@keyframes defilement-rtl {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
  }
}

//TODO Madrassa
/*
div[data-square="madrassa"] {
  position: relative;
  transition: 1s box-shadow;
}

div[data-square="madrassa"]::before,
div[data-square="madrassa"]::after {
  display: block;
  content: "";
  position: absolute;
  width: 500px;
  height: 270px;
  background: #fda8cf;
  border-radius: 10px;
  z-index: -1;
  animation: 1s clockwise infinite;
}

div[data-square="madrassa"]:after {
  background: #f3ce5e;
  animation: 2s counterclockwise infinite;
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;
  }
}
*/
