.titre-faq {
  text-align: center;
  font-size: 2rem;
  margin: 4rem 0;
}

.faqText {
  margin-bottom: 0;
}

@media (min-width: $tablet-landscape) {
  .faqContainer {
    margin: auto;
    width: 60%;
  }
}
