.whapp {
  color: #3bb927;
}

.standard {
  color: #85b2dc;
}

.mail {
  color: #c479a3;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.icone {
  width: 200px;
  height: auto;
}

.container-icone {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.textContact {
  text-align: center;
}
.number {
  text-align: center;
}

.formulaire {
  margin-bottom: 5rem;
}

@media (min-width: $laptop) {
  .block-green {
    display: flex;
    margin-top: 3rem;
    width: 40%;
  }
  .content-contact {
    margin: auto;
  }
}
.btn-success{
  margin-left: 0px!important;
}
