@font-face {
  font-family: "roboto-condensed";
  src: url("../../assets/fonts/roboto-condensed-webfont.woff"),
    url("../../assets/fonts/roboto-condensed-webfont.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "blenda";
  src: url("../../assets/fonts/blenda_script-webfont.woff"),
    url("../../assets/fonts/blenda_script-webfont.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsR";
  src: url("../../assets/fonts/Poppins-Regular.woff"),
    url("../../assets/fonts/Poppins-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsL";
  src: url("../../assets/fonts/Poppins-Light.woff"),
    url("../../assets/fonts/Poppins-Light.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsM";
  src: url("../../assets/fonts/Poppins-Medium.woff"),
    url("../../assets/fonts/Poppins-Medium.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ETmodules";
  src: url("../../assets/fonts/ETmodules_v2.woff.ttf");
  font-display: swap; //affiche une police de remplacement pendant le chargement de la police
}

/*@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Segoe-UI.woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Segoe-UI-Italic.woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Segoe-UI-Bold.woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Segoe-UI-Bold-Italic.woff");
}*/

@font-face {
  font-family: "Aleo";
  font-weight: 400;
  src: url("../../assets/fonts/Aleo-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Aleo";
  font-weight: 700;
  src: url("../../assets/fonts/Aleo-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Syifa";
  font-weight: normal;
  src: url("../../assets/fonts/Syifa.ttf");
  //font-display: swap;
}
