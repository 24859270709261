.container-badge {
  @include flex-center-column;
  width: 75%;

  .img-badge {
    width: 17rem;
    margin-left: 1rem;
  }

  .text-badge {
    text-align: center;
  }

  .text-badge-form {
    text-align: center;
    font-size: 0.9rem;
  }
}

.container-progressbar {
  @include flex-center-column;

  .progressbar[value] {
    width: 15rem;
    height: 3rem;
  }
}
