// ---------- RESPONSIVE -----------
@media (min-width: $tablet-landscape) {
  .formulaire {
    margin: 0 auto 2rem auto;
    width: 40%;
  }
}

// --------- GÉNÉRAL ----------

textarea.msg.form-control {
  height: 200px;
}

.title-form {
  text-align: center;
}

// --------- FORMS CONTACT ----------------

.fleche-form {
  width: 40px;
  height: auto;
}

// ------------ FORMS RECRUTEMENT ---------------
.btn.btn-recrut {
  background-color: $blue;
  color: #fff;
  font-weight: bold;
    margin-left: 0;
}
