.stf__parent {
  position: relative;
  display: block;
  box-sizing: border-box;
  transform: translateZ(0);
  //max-width: 94vw !important;

  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.sft__wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  //max-width: 94vw !important;
}

.stf__parent canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.stf__block {
  position: absolute;
  width: 100%;
  // height: 100%;
  box-sizing: border-box;
  perspective: 2000px;
  //max-width: 94vw !important;
}

.stf__item {
  display: none;
  position: absolute;
  transform-style: preserve-3d;
}

.stf__outerShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.stf__innerShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.stf__hardShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.stf__hardInnerShadow {
  position: absolute;
  left: 0;
  top: 0;
}

.source {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: relative;
  display: none;

  .source-block {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 301;

    overflow: hidden;

    pre {
      display: block;
      position: relative;

      code {
        font-size: 90%;
      }
    }
  }
}

.demo-block {
  border-top: solid 1px #dcdcdc;
  border-bottom: solid 1px #dcdcdc;
}

.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  display: none;
  z-index: 100;
  cursor: pointer;
  width: fit-content !important;
  // height: 100% !important;
  //margin-bottom: 1rem;
  //min-width: 0px !important;
  //max-width: 94vw !important;
}

.html-book {
  //background-size: cover;
}

.page {
  padding: 1rem;
  background-color: #fff;
  color: hsl(35, 35%, 35%);
  border: solid 1px hsl(35, 20%, 70%);
  overflow: hidden;
  // height: 100% !important;
  padding-bottom: 2rem;
  max-width: 94vw !important;
  .page-content {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    max-width: 94vw !important;

    .page-header {
      height: 30px;
      font-size: 100%;
      text-transform: uppercase;
      text-align: center;
    }

    .page-image {
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .page-text {
      height: 100%;
      flex-grow: 1;
      font-size: 80%;
      text-align: justify;
      margin-top: 10px;
      padding-top: 10px;
      box-sizing: border-box;
      border-top: solid 1px hsl(35, 55%, 90%);
    }

    .page-footer {
      height: 30px;
      border-top: solid 1px hsl(35, 55%, 90%);
      font-size: 80%;
      color: hsl(35, 20%, 50%);
    }
  }

  &.--left {
    border-right: 0;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
  }

  &.--right {
    border-left: 0;
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);

    .page-footer {
      text-align: right;
    }
  }

  &.hard {
    background-color: #fff;
    border: solid 1px hsl(35, 20%, 50%);
  }

  &.page-cover {
    background-color: #fff;
    color: hsl(35, 35%, 35%);
    border: solid 1px hsl(35, 20%, 50%);

    h2 {
      text-align: center;
      padding-top: 50%;
      font-size: 210%;
    }

    &.page-cover-top {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
        -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    }

    &.page-cover-bottom {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
        10px 0 8px 0px rgba(0, 0, 0, 0.4);
    }
  }
}
