.formPadding{
  padding-right: 0px!important;
  padding-left: 0px!important;
}.main-recrut {
  margin: 2rem 0;
}

.first-text {
  width: 90%;
  margin: 1rem auto;

  .title-recrut {
    font-size: 1.4rem;
    font-family: $base-font-family;
    font-weight: $bold;
    text-transform: uppercase;
  }

  .border-recrut {
    margin: 0.7rem 0;
    border-bottom: 3px solid #3ed1e7;
    width: 25%;
  }

  .text-recrut {
    font-family: $base-font-family;
    font-size: 0.95rem;
    font-weight: normal;
  }
}

.text-recrut2 {
  text-align: center;
  font-family: $base-font-family;
  font-size: 0.95rem;
}

@media (min-width: $tablet-landscape) {
  .first-text {
    width: 60%;

    .title-recrut {
      font-size: 1.6rem;
    }

    .border-recrut {
      width: 7%;
    }
  }
}
