.hide {
  display: none;
}

.container-parent-form {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.title-choice-parent {
  font-size: 0.9rem;
  font-weight: $bold;
  text-transform: uppercase;
  margin: 1rem 0;
}

.title-choice-form {
  font-size: 1rem;
  font-weight: $bold;
  font-family: $base-font-family;
  letter-spacing: 0.5px;
}

.text-choice-parent {
  font-size: 0.9rem;
}

.label-choice {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.selectClass {
  display: block;
  width: 100%;
  height: calc(1.5rem + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-choice {
  padding: 0.2rem 1rem;
  border: none;
  background-color: rgba(233, 233, 233, 1);
  border-radius: 0.3rem;

  &:focus {
    outline: none;
  }
}

.border-form {
  width: 65%;
  margin: 0.5rem auto 2rem auto;
  border: 0.5px solid $borderGrey;
}

.btn-form {
  display: flex;
  margin: 1rem auto;
  border: none;
  background-color: $green;
  color: $white;
  width: 80%;
  padding: 0.5rem 3rem;
  height: 3rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
  }
}

@media (min-width: $tablet-landscape) {
  .container-parent-form,
  .btn-sub {
    width: 50%;
    margin: auto;
  }

  .border-form {
    width: 50%;
    margin: 1rem auto;
  }

  .formSub {
    display: flex;
    flex-direction: column;
  }

  .input-choice {
    width: 100%;
  }
}
